import React from "react";
import get from "lodash/get";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import { getUrlFor } from "../utils/imageUtils";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import AlsoRead from "../components/AlsoRead";
import withGraphQLErrors from "../hocs/withGraphQLErrors";
import ContentLayout from "../primitives/ContentLayout";
import SectionLayout from "../primitives/SectionLayout";
import ReverseOrderLayout from "../primitives/ReverseOrderLayout";
import PortableText, {
  InternalLinkRenderer,
  ExternalLinkRenderer,
} from "../primitives/PortableText";
import styles from "./Publication.module.scss";

export const query = graphql`
  query PublicationPageQuery($slug: String!) {
    publication: sanityPublication(slug: { current: { eq: $slug } }) {
      ...SanityPublicationFullFragment
    }
  }
`;
const PublicationTemplate = ({ data }) => {
  const { publication = {} } = data || {};
  const headerImgUrl =
    publication.headerImage &&
    getUrlFor(publication.headerImage)
      .width(1075)
      .height(604)
      .fit("crop")
      .url();

  return (
    <Layout>
      <Seo
        title={get(publication, "meta.metaTitle", "")}
        description={get(publication, "meta.metaDescription", "")}
        image={get(publication, "headerImage", null)}
      />

      <ContentLayout>
        <header>
          <ReverseOrderLayout>
            <h1 className={styles.mainTitle}>{publication.title}</h1>
            <p className={styles.mainSubtitle}>{publication.subtitle}</p>
          </ReverseOrderLayout>
        </header>
        <div className={styles.lede}>
          <BlockContent
            blocks={publication._rawLeadParagraph}
            serializers={{
              marks: {
                internalLink: InternalLinkRenderer,
                externalLink: ExternalLinkRenderer,
              },
            }}
          />
        </div>
        <div className={styles.headerImage}>
          <img src={headerImgUrl} alt={publication.headerImageAlt} />
        </div>
        <div className={styles.headerMeta}>
          <p className={styles.dateAuthor}>
            Publié en{" "}
            <time dateTime={publication.publishedAt}>
              {publication.publishedAtLocaleFr}
            </time>
            <br />
            Par {publication.author}
          </p>
        </div>
      </ContentLayout>

      <ContentLayout>
        {publication._rawContent &&
          publication._rawContent.map(
            ({ _key, sectionContent, backgroundColor }) => (
              <SectionLayout backgroundColor={backgroundColor} key={_key}>
                <PortableText blocks={sectionContent} />
              </SectionLayout>
            )
          )}
      </ContentLayout>

      {publication.linkPublications && publication.linkPublications.length > 0 && (
        <ContentLayout>
          <AlsoRead posts={publication.linkPublications} />
        </ContentLayout>
      )}
    </Layout>
  );
};

export default withGraphQLErrors()(PublicationTemplate);
